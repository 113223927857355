import { useEffect, useState } from "react"
import { columns } from "./columns"
import { DataTable } from "./data-table"
import { fetchPost } from "../../../../utils/helpers";
import { Urls } from "../../../../utils/AppConfig";


export default function QuoteContractTable() {

  const [quoteData, setQuoteData] = useState([]);
  
  useEffect(() => {
    let url = Urls.GetQuoteContracts;
    let token = window.localStorage.getItem('token');
    fetchPost(url, {}, token)
      .then(response => {
        console.log(response);
        if (response.error) {
          console.log("Error");
          setQuoteData([]);
        } else {
            setQuoteData(response.data)

        }
      })
      .catch(err => console.log(err));
  }, [])

  return (
    <div>
      <DataTable columns={columns} data={quoteData} />
    </div>
  )
}
