import * as React from 'react';
import Map from '../components/Map';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Theme } from '../utils/AppConfig'
import { useSearchParams } from "react-router-dom";
import { LoadByAddress } from '../services/index.js';
import HomeHeader from '../components/HomeHeader.js';
import SidePanel from '../components/Map/SidePanel.js';
import ShowSamplePlans from '../components/ShowSamplePlans';

const drawerWidth = 240;
const navItems = [];
const ButtonStyle = { color: Theme.PrimaryLogo, fontFamily: 'Verdana, sans-serif', fontWeight: 'bold' }

const mapStateToProps = (state) => {
    return {
        data: state.DataReducer,
        map: state.MapReducer,
        dialog: state.DialogReducer
    }
}


function MapPage(props) {
    const [rpWidth, setRpWidth] = useState(0);

    useEffect(() => {
        if (Boolean(props.data.currentParcelId)) {
            setRpWidth(500)
        } else {
            setRpWidth(0)
        }
    }, [props.data.currentParcelId])

    const dispatch = useDispatch();



    /**
     * GET parameters from url & load the plot detatils if it exists
     * /map/q=124012-160-princess-street-pukekohe
     * /map/<address_id>-<full_address>
     * /map/1061215-61 Maioro Street, New Windsor, Auckland
     */

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let query = searchParams.get("q");
        try {
            if (query) {
                let addressId = parseInt(query.split("-")[0]);
                LoadByAddress(addressId, dispatch);
                console.log(addressId);
            }
        } catch (e) {
            console.log(e);
        }
    }, [])

    return (<>

        <HomeHeader />
        {/* <SidePanel /> */}
        <Map />
        {props.dialog.samplePlansDialog.flag ? (
                        <ShowSamplePlans />) : ''}
    </>);
}

export default connect(mapStateToProps)(MapPage);