// lib/utils.ts
import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { Urls } from "../utils/AppConfig";
import { fetchPost } from "../utils/helpers";
import { useEffect, useState } from "react"

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const ColorMap = {
  3: "success",
  2: "danger",
  4: "warning",
  1:"primary",
  0:"default"
};

export const statusMap = {
  3: "Approved",
  2: "Rejected",
  4: "Corrections",
  1:"In-Progress",
  0:"In-Active"
};

export const quoteTypeMap = {
  3: "AUTO",
  2: "NEW",
  4: "MANUAL",
  1:"CONCEPT DESIGN",
};

export const quoteTypeColorMap = {
  3: "bg-purple-500 text-white",
  2: "bg-yellow-500 text-black",
  4: "bg-pink-500 text-white",
  1:"bg-white text-black",
};

export const contractStatusMap = {
  3: "Approved",
  2: "Verification In-Progress",
  1:"Upload Contract",
  0:"Pending"
};


export function getFileExtension(fileName){  
  return fileName.split('.').pop();
}


export function secondsToDhms(seconds) {
  seconds = Number(seconds)
  var d = Math.floor(seconds / (3600 * 24))
  var h = Math.floor((seconds % (3600 * 24)) / 3600)
  var m = Math.floor((seconds % 3600) / 60)
  var s = Math.floor(seconds % 60)
  // console.log(d, h, m, s)
var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : ""
  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : ""
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : ""
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
  return dDisplay + hDisplay + mDisplay + sDisplay
}

export const crmAppStep =  {
  
2:"2"
  // 

  // useEffect(() => {
  //   crmAppStep();


    
  // }, []);

  //   let url = Urls.GetCRMAppStep;
  //   let token = window.localStorage.getItem('token');
  //   fetchPost(url, {}, token)
  //     .then(response => {
  //       console.log(response);
  //       if (response.error) {
  //         console.log("Error");
  //         setCrmAppStepData([]);
  //       } else {
  //         setCrmAppStepData(response.data)

  //       }
  //     })
  //     .catch(err => console.log(err));
};








