import HomeHeader from "../components/HomeHeader";
import QuoteContractTable from "../components/Profile/plotworks/contracts/page";

const QuoteContractsPage = (props) => {
    return <>
        <HomeHeader />
        <div className="items-center justify-center bg-transparent text-white text-center">
    <div className="p-6 px-0">
    <h3 className="text-[#00ff99] p-3">Quotation Contracts</h3>
    <QuoteContractTable />
</div>
</div>
    </>
}

export default QuoteContractsPage;