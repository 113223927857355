import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchPost } from "../utils/helpers";
import SuccessImg from '../images/ic_success.png';
import FailureImg from '../images/ic_failure.png';
import LoadingImg from '../images/loading.gif';
import { toggleLoader } from "../actions";
import { useDispatch } from "react-redux";
import { Urls } from "../utils/AppConfig";
import HomeHeader from "../components/HomeHeader";
import Contract from "../components/PlotWorks/Contract";


const QuoteContractPage = (props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    let quoteId = searchParams.get("quoteId");

    return <>
    <HomeHeader />
        <div className="items-center justify-center bg-transparent text-white text-center">
    <div className="p-6 px-0">
    <Contract props={quoteId} />
</div>
</div>
    </>
}

export default QuoteContractPage;