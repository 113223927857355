
import React, { useEffect } from 'react';
import {Accordion, AccordionItem} from "@nextui-org/react";

import Applications from "../../images/plotworks/Applications.png";
import AssignConsultant from "../../images/plotworks/AssignConsultant.png";
import Chat1 from "../../images/plotworks/Chat1.png";
import Chat2 from "../../images/plotworks/Chat2.png";
import Chat3 from "../../images/plotworks/Chat3.png";
import Council from "../../images/plotworks/Council.png";
import Council2 from "../../images/plotworks/Council2.png";
import Home from "../../images/plotworks/Home.png";
import ManualReport from "../../images/plotworks/ManualReport.png";
import Query from "../../images/plotworks/Query.png";
import Quote1 from "../../images/plotworks/Quote1.png";
import Quote2 from "../../images/plotworks/Quote2.png";
import Quote3 from "../../images/plotworks/Quote3.png";
import Task from "../../images/plotworks/Task.png";
import Task2 from "../../images/plotworks/Task2.png";
import ApplicationsDetails from "../../images/plotworks/ApplicationsDetails.png";
import Applications2 from "../../images/plotworks/Applications2.png";
import Files from "../../images/plotworks/Files.png";
import Files2 from "../../images/plotworks/Files2.png";
import Files3 from "../../images/plotworks/Files3.png";



import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Navigation, Pagination, Scrollbar,Autoplay,EffectCoverflow,EffectFlip,EffectCreative,Zoom } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-creative';
import 'swiper/css/zoom';
import { Link } from 'react-router-dom';



const SwiperImages = ({swipeData}) => {        
  return (
    <Swiper
  modules={[Navigation, Pagination, Scrollbar,Autoplay,EffectCreative,EffectCoverflow,EffectFlip,Zoom]}
  centeredSlides={true}
  grabCursor={true}

  // effect={'flip'}

  effect={'coverflow'}
  slidesPerView={'auto'}
  coverflowEffect={{
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  }}
  // effect={'creative'}
  // creativeEffect={{
  //   prev: {
  //     shadow: true,
  //     translate: [0, 0, -400],
  //   },
  //   next: {
  //     translate: ['100%', 0, 0],
  //   },
  // }}
  spaceBetween={30}
  loop={true}
  onSwiper={(swiper) => console.log(swiper)}
  onSlideChange={() => console.log('slide change')}
  autoplay={{
    delay: 1500,
    disableOnInteraction: false,
  }}
>
{swipeData.map((item,idx)=>(
          <SwiperSlide><img src={item} className='w-full h-[250px] object-fill border-2 border-[#00ff99] rounded-lg'/></SwiperSlide>
            ))}
</Swiper>
        )}


const CrmSection = () => {

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const services =
    [
        {
            id:"1",
            title:"Design management portal",
            content:"Your project's portal gives you an overview of all current and future tasks related to your design, as well as up-to-date information on their progress. Assign tasks to people, keep track of their progress, and easily stick to deadlines.",
            side:'left',
            images:[Applications,ApplicationsDetails,Applications2,ManualReport]
          },
        {
            id:"2",
            title:"Build Mangement Portal",
            content:"The build management platform maps out all stages of the building process and CMP (construction management programme). It also provides pre-inspection checklists for each stage, to give you confidence that all boxes are ticked before the council inspections.",
            side:'right',
            images:[Council,Council2,Quote2,Query]
          },
        {
            id:"3",
            title:"File and document management",
            content:"Our platform organises and saves all relevant paperwork as your project goes through the design process. You can edit view permissions to allow consultants and other stakeholders access to documentation as necessary.",
            side:'left',
            images:[Files,Files2,Files3]
          },
        {
            id:"4",
            title:"Chat with consultants and stakeholders",
            content:"The platform has a centralised communication system that allows you to chat directly with consultants and stakeholders.",
            side:'right',
            images:[Chat1,Chat2,Chat3]
          },
        {
            id:"5",
            title:"Transparent task and time management",
            content:"Jobs, tasks and deadlines can be directly assigned to consultants, ensuring accountability and timeliness. Easily view tasks' progress, and flag where tasks are slipping behind.",
            side:'left',
            images:[Task,Task2,AssignConsultant]
          }
    ];

    return <>
      <section  className='scroll-mt-[72px]' style={{ fontFamily: 'Verdana, sans-serif' }}>
            <div style={{ textAlign: 'center', padding: 0, background: '#111111', paddingTop: 65, paddingBottom: 65 }}>
                <div elevation={0} style={{ textAnchor: 'middle', background: 'transparent', minHeight: '100vh' }}>
                <p className="head_font2 md:text-5xl text-4xl font-bold text-[#00ff99] pt-5 ">
                DESIGN & BUILD MANAGEMENT PLATFORM
                    </p>
                    <div className='content-text justify-center items-center text-center py-4'>
                    Through the Plot Works portal, you can track the progress of projects, access your design concepts, review plans, and communicate with your team.
                                         </div>

                    <div className='content-text  flex justify-center items-center text-center pt-3 pb-5'>
                    <div className='lg:w-3/4'>
                    Say goodbye to missed calls and endless follow-ups with consultants. With all your documents and progress updates in one central location, staying on top of your projects has never been easier. 
                    </div>
                    </div>



            <div className=' flex items-center justify-center'>
            <div className='md:w-3/4 w-11/12'>


                    <Accordion variant='splitted'
            itemClasses={
                {
                    base:"!bg-[#333333] text-white hover:bg-gradient-to-r from-[#07b16d88] to-[#71aec088]  hover:text-white",
                    title:"text-white"
                }
            }
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            height: "auto",
            transition: {
              height: {
                type: "spring",
                stiffness: 500,
                damping: 30,
                duration: 1,
              },
              opacity: {
                easings: "ease",
                duration: 1,
              },
            },
          },
          exit: {
            y: -10,
            opacity: 0,
            height: 0,
            transition: {
              height: {
                easings: "ease",
                duration: 0.25,
              },
              opacity: {
                easings: "ease",
                duration: 0.3,
              },
            },
          },
        },
      }}
    >
            {services.map((item,idx)=>(
                <AccordionItem key={item.id} aria-label={item.id} title={item.title} className='text-left text-xl' >


         {item.side == 'right'? 
           <div className='lg:space-x-4 flex space-y-4 lg:flex-row flex-col'>
          <div className='lg:w-5/12 w-full'>
          <SwiperImages swipeData={item.images} />
          </div>

               <div className='lg:w-7/12 w-full'>
               {item.content}
               </div>
               </div>
          : 
              <div className='lg:space-x-4 space-y-4 flex lg:flex-row flex-col'>
                <div className='lg:w-7/12 w-full'>
                    {item.content}
                    </div>
                  <div className='lg:w-5/12 w-full'>
                  <SwiperImages swipeData={item.images} />
                  </div>
              </div>
        }
                </AccordionItem>
            ))}
            </Accordion>
            </div>
            </div>
                    </div>
                    </div>
                    </section>
</>
}





export default CrmSection;