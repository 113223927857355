
import { Card, CardContent, CardFooter, CardTitle, CardHeader } from "../../shadcn/components/ui/card"
import { Separator } from "../../shadcn/components/ui/separator"
import { Button } from "../../shadcn/components/ui/button"
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChevronRightIcon, ChevronLeftIcon, DownloadIcon,FileTextIcon } from "@radix-ui/react-icons"
import { fetchGet, fetchPost, toSlug } from "../../utils/helpers";
import { useEffect, useState } from "react";
import { Urls } from "../../utils/AppConfig";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../actions";
import {Checkbox, CheckboxGroup, Link, User, Chip, cn} from "@nextui-org/react";
import React from "react";
import { Button as NextUIButton } from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,Tooltip
} from "@nextui-org/react";
import {Avatar, AvatarIcon} from "@nextui-org/react";
import { useHistory } from "react-router-dom";
import {Select, SelectItem} from "@nextui-org/react";

import { statusMap } from "../../lib/utils";


export default function Quotation(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [reportData, setReportData] = useState(null);
  const [quoteData, setQuoteData] = useState(null);
  const [quoteList, setQuoteList] = useState(null);
  const [isInvalid, setIsInvalid] = React.useState(true);

  const [primaryContact, setPrimaryContact] = useState(null);


  const [quoteStatus,setquoteStatus] = useState(null);

    const [quoteIds, setquoteIds] = React.useState([]);
      const [consultantData, setConsultantData] = useState(null);
  
    const {isOpen, onOpen, onClose} = useDisclosure();
    const quoteModal = useDisclosure();
    const approveModal = useDisclosure();
    const [quoteCost, setQuoteCost] = useState([]);


  let quoteTypeId = searchParams.get("quoteTypeId");
  let quoteType = searchParams.get("quoteType");
  let reportTypeId = searchParams.get("quoteTypeId");
  let reportType = searchParams.get("quoteType");

  const dispatch = useDispatch();

  let token = window.localStorage.getItem('token');
  useEffect(() => {
    loadData2()
    loadData()
  }, [props]);

  function Capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  const loadData2 = () => {
    fetchPost(Urls.GetReportTypeDetails, { reportType,reportTypeId }, token)
      .then(r => {
        console.log(r);
        if (r.error) {
          return window.alert("Server error");
        }
        setReportData(r.data[0]);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const loadData = () => {
    fetchPost(Urls.GetQuoteDetails, { quoteType,quoteTypeId }, token)
      .then(r => {
        console.log(r);
        if (r.error) {
          return window.alert("Server error");
        }
        setQuoteList(r.data);
      })
      .catch(err => {
        console.log(err);
      })
      setReportData(props);
  }

  if (!quoteList) {
    return '...';
  }

  function selectQuoteCost(selectedQuoteCost,e){
    
    const { value, checked} = e.target;
  
   if (checked) {
    setQuoteCost([...quoteCost,selectedQuoteCost]);
  }else{
    setQuoteCost(quoteCost.filter(item => item !== selectedQuoteCost));
      }

  }


   const CustomCheckbox = ({quote, statusColor, value,selectedQuote}) => {

    return(
      <>
      
   <Checkbox
        color="success"
          aria-label={quote?.comp_name}
          classNames={{
            base: cn(
              "inline-flex max-w-md w-full bg-content1 m-0",
              "hover:bg-content2 items-center justify-start",
              "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
              "data-[selected=true]:border-[#00ff99]",
            ),
            label: "w-full",
            icon:'opacity-1'
          }}
          value={value}
          onChange={(e)=>selectQuoteCost(selectedQuote,e)}
        >
           <div className="gap-2 flex flex-col">
        <div className="w-full flex justify-between gap-2">
           <User
              avatarProps={
                {
                classNames:{
                  img:'opacity-1',
                  icon: "text-[#00ff99]/80",
                },
                size: "lg", 
                // icon:<AvatarIcon/>,
                src:quote.profile_img
              }}
  
              description={
                <p >
                {quote.user_type}
              </p>
              
              }
              name={quote.name}
            />
            <div className="flex flex-col items-end gap-1">
              <span className="text-sm text-default-500">{quote.timeline}</span>
              <Chip color={statusColor} size="md" variant="flat">
                {quote.cost}
              </Chip>
            </div>
        </div>
           <div className="flex items-center justify-between">
            <Tooltip color="primary" content="Company Profile" showArrow={true}>
            <NextUIButton color="primary" size="sm" onClick={() => getConsultantProfile(quote.consultant_id)}>Consultant Details</NextUIButton>
          </Tooltip>
          <Tooltip color="secondary" className="bg-[#9353d3] " content="Quote Additional info" showArrow={true}>
            <NextUIButton size="sm" onClick={() => getQuoteData(quote.quote_id)} className="bg-[#9353d3]">View Quote</NextUIButton>
          </Tooltip>
            </div>
           </div>
        </Checkbox>
      </>
    )  };
  

  async function getConsultantProfile(consultantId){

    try {
    fetchPost(Urls.GetConsultantProfile, { consultantId }, token)
         .then(r => {
           console.log(r);
           if (r.error) {
             return window.alert("Server error");
           }
           setConsultantData(r.data[0]);
           onOpen();
         })
         .catch(err => {
           console.log(err);
         })
    } catch(error) {
        console.log(error)
    }
  
  }
  
  async function getQuoteData(quoteId){
      try {
    fetchPost(Urls.GetQuoteDetailsById, { quoteId }, token)
         .then(r => {
           console.log(r);
           if (r.error) {
             return window.alert("Server error");
           }
           setQuoteData(r.data);
           quoteModal.onOpen();
         })
         .catch(err => {
           console.log(err);
         })
    
    } catch(error) {
        console.log(error)
    }
  }

function ApproveModal(stat){

  let selectedItems = quoteIds.length;

if(stat == 3 && selectedItems < 1)
 return alert("Select at least One");

if(primaryContact == null)
  return alert("Select Primary Contact !");

  setquoteStatus(stat);
  approveModal.onOpen();

}

function quoteApprove(){

    dispatch(toggleLoader(true));
    fetchPost(Urls.ApproveQuote, {quoteStatus,quoteType,quoteTypeId,quoteIds,primaryContact}, token)
      .then(response => {
        dispatch(toggleLoader(false));
        if (response.error) {
          return window.alert("Failed to Approve Quote. Please, try again later.");
        }
        window.location.href = '/plotworks/quotation';

          // navigate("/plotworks/quotation");
      });
}




  return (

<>

<Modal isOpen={approveModal.isOpen} onClose={approveModal.onClose} placement="center" backdrop="blur">
        <ModalContent>
              <ModalHeader className="flex flex-col gap-1 text-white">Confirm</ModalHeader>
              <ModalBody>
                <p className="text-white"> 
                 Are You Sure ! You Want to {quoteStatus  == 2 ? 'Reject' : 'Accept'} this Quote ?
                </p>
              </ModalBody>
              <ModalFooter>
                <NextUIButton color="danger"  onPress={approveModal.onClose}>
                Cancel
                </NextUIButton>
                <NextUIButton color="success" onClick={quoteApprove}>
                  Confirm
                </NextUIButton>
              </ModalFooter>
        </ModalContent>
      </Modal>



<Modal isOpen={isOpen} size={'2xl'} onClose={onClose} scrollBehavior="outside" placement="center" backdrop="blur">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col items-center justify-center text-white gap-1">Consultant Profile</ModalHeader>
              <ModalBody>

              <div className="py-2 px-4 flex-col ">
                  <div className="flex items-center justify-center ">
                  <Avatar 
                  classNames={
                    {
                      img:'opacity-1 object-cover'
                    }
                  }
                  // icon={<AvatarIcon/>}
                  src={consultantData.profileUrl}
                  // color="primary" 
                  radius="lg"  className="w-24 h-24 text-large border-[#00ff99] border-2"/>
                  </div>
                  </div>
                  <div className=" flex flex-col items-center justify-center">
                    <div>
                    <h4 className="font-bold text-white text-base">{consultantData.comp_name}</h4>
                    </div>
                  <div className="pt-1">
                  <Chip
                        variant="shadow"
                        classNames={{
                          base: "bg-[#4C3C7B] border-small border-white/50 shadow-[#916471]/30",
                          content: "drop-shadow shadow-black text-[#EEA3B5]",
                          
                        }}
                      >
                        {consultantData.consultant_type}
                      </Chip>
                  </div>
                  </div>
              
                    <div className="flex justify-center p-10">
                
                
              <div className='px-5'>
              <p className="flex items-center text-white justify-between">
              <span> No of Emp</span>
              <span> - </span>
                <span className="">{consultantData.no_of_emp}</span>
              </p>

              <p className="flex items-center text-white justify-between">
              <span> No of Jobs</span>
              <span> - </span>
                <span className="">{consultantData.no_of_jobs}</span>
              </p>
              <p className="flex items-center text-white justify-between">
              <span> Total Exp</span>
              <span> - </span>
                <span className="">{consultantData.comp_exp}</span>
              </p>
              <p className="flex items-center text-white justify-between">
              <span> Avg Lodgement </span>
              <span> - </span>
                <span className="">{consultantData.lodgement_days}</span>
              </p>
              </div>
              </div>

              </ModalBody>
              <ModalFooter>
                <NextUIButton color="danger"  onPress={onClose}>
                  Close
                </NextUIButton>
              
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal isOpen={quoteModal.isOpen} size={'2xl'} onClose={quoteModal.onClose} scrollBehavior="outside" placement="center" backdrop="blur">
        <ModalContent>
            <>
              <ModalHeader className="flex flex-col items-center justify-center text-white gap-1">Quotation Details</ModalHeader>
              <ModalBody>
              
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 items-center justify-center">

<div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
  <div className="text-[#00ff99] font-extrabold ">Quote Name</div>
  <div className="font-medium text-white">{quoteData?quoteData.quote_name:''}</div>
</div>
<div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
  <div className="text-[#00ff99] font-extrabold ">Quotations By</div>
  <div className="font-medium text-white">{quoteData?quoteData.quote_by:''}</div>
</div>

<div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
  <div className="text-[#00ff99] font-extrabold "> From Date  </div>
  <div className="font-medium text-white">{quoteData?quoteData.quote_from_date:''}</div>
</div>
<div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
  <div className="text-[#00ff99] font-extrabold ">To Date</div>
  <div className="font-medium text-white">{quoteData?quoteData.quote_to_date:''}</div>
</div>
<div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
  <div className="text-[#00ff99] font-extrabold "> Lodgement TimeLine  </div>
  <div className="font-medium text-white">{quoteData?secondsToDhms(quoteData.quote_timeline_duration):''}</div>
</div>
<div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
  <div className="text-[#00ff99] font-extrabold ">Total Cost</div>
  <div className="font-medium text-white">{quoteData?quoteData.quote_total_cost:''}</div>
</div>
<div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
  <div className="text-[#00ff99] font-extrabold ">Quote Created on </div>
  <div className="font-medium text-white">{quoteData?quoteData.quote_created_on:''}</div>
</div>

<div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
  <div className="text-[#00ff99] font-extrabold ">Summary </div>
  <div className="font-medium text-white">{quoteData?quoteData.summary:''}</div>
</div>
</div>
<div className="grid grid-cols-1 gap-3 items-center justify-center">
<div className="text-[#00ff99] font-extrabold text-center ">Template</div>

<div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
  <div className="font-medium text-white">
    <pre>
    {quoteData?quoteData.template_content:''}
      </pre></div>
</div>
</div>


              </ModalBody>
              <ModalFooter>
                <NextUIButton color="danger"  onPress={quoteModal.onClose}>
                  Close
                </NextUIButton>
              
              </ModalFooter>
            </>
        </ModalContent>
      </Modal>




   <div className="flex items-center justify-center w-full py-3 px-2">
      <div className="md:p-5 p-1 w-full  mt-5">
      <div style={{float:'left'}} className="py-4 pl-4">
              <Button className="font-bold" variant="" onClick={() => {
                navigate("/plotworks/quotation")
              }}>
                Back
              </Button>
            </div>
        <Card className="w-full  p-0 text-white border-none bg-[#111111] border-2">
          <CardHeader className="pl-0">
            <div className="flex  items-center justify-center gap-4">
              <div className="font-medium"><ReportIcon height={60} width={60} className=" text-[#00ff99]" /></div>
              <div><CardTitle className="text-[#00ff99]"> Quotation Report Details</CardTitle></div>
            </div>
          </CardHeader>

          <CardContent>
            <div style={{ textAlign: 'center' }}>
              {/* <div onClick={() => {
                window.open(reportData['previewUrl'], '_blank');
              }} style={{ cursor: 'pointer', display: 'inline-block', background: '#333', padding: 20, borderRadius: 10, boxShadow: '2px 2px 2px 2px black', marginBottom: 10 }}>
                <img src={reportData['previewUrl']} style={{ maxWidth: 300, maxHeight: 300, objectFit: 'contain' }} />
              </div> */}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 items-center justify-center">

              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Report ID</div>
                <div className="font-medium">{reportData.report_id}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Order No</div>
                <div className="font-medium">{reportData.order_ref}</div>
              </div>

              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Parcel ID </div>
                <div className="font-medium">{reportData.parcel_id}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Address</div>
                <div className="font-medium">{reportData.full_address}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Quote Requested on </div>
                <div className="font-medium">{reportData.quote_requested_at}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Quote Status </div>
                <div className="font-medium">{statusMap[reportData.quote_status]}</div>
              </div>
            </div>
          
          </CardContent>


          <div className="flex flex-col gap-1 w-full">
      <CheckboxGroup
        classNames={{
          base: "w-full",
          label:" text-[#00ff99] text-2xl"
        }}
        label="Select Quotations"
        value={quoteIds}
        onChange={setquoteIds}
      >
      <div className="grid grid-cols-12 w-full lg:w-9/12   gap-y-6 gap-x-4 place-self-center">
            {
                quoteList.map((quote) => (
                    <div key={quote.id} className=" lg:col-span-6  col-span-12 " >
        <CustomCheckbox
          statusColor="success"
          quote={{
            name: quote.comp_name,
            // avatar: "https://avatars.githubusercontent.com/u/30373425?v=4",
            user_type: quote.consultant_type,
            url: "https://x.com/jrgarciadev",
            timeline:secondsToDhms(quote.quote_timeline_duration),
            cost:"NZ$ - " + quote.quote_total_cost,
            consultant_id:quote.quote_created_by,
            quote_id:quote.quote_id,
            consultant_type:quote.consultant_type,
            profile_img:quote.profileUrl

          }}
          value={quote.quote_id}
          selectedQuote ={quote.quote_total_cost}
        />
             </div>
                ))
            }
    </div>
      </CheckboxGroup>


<div className="pt-5 pb-3 justify-center flex">
<Select
isRequired
      classNames={{
        base: "max-w-xs",
        trigger: "h-12",
      }}
      items={quoteList}
      label="Point of Communication (Primary)"
      labelPlacement="outside"
      placeholder="Select a Consultant"
      onChange={(e)=>setPrimaryContact(e.target.value)}
      renderValue={(items) => {
        return items.map((item) => (
          <div key={item.key} className="flex items-center gap-2">
            <Avatar
              alt={item.data.comp_name}
              className="flex-shrink-0"
              size="sm"
              src={item.data.profileUrl}
            />
            <div className="flex flex-col">
              <span>{item.data.comp_name}</span>
              <span className="text-default-500 text-tiny">({item.data.email})</span>
            </div>
          </div>
        ));
      }}
    >
      {(user) => (
        <SelectItem key={user.user_id} textValue={user.comp_name} className="pl-0">
          <div className="flex gap-2 items-center">
            <Avatar alt={user.comp_name} className="flex-shrink-0" size="sm" src={user.profileUrl} />
            <div className="flex flex-col">
              <span className="text-small">{user.comp_name}</span>
              <span className="text-tiny text-default-400">{user.email}</span>
            </div>
          </div>
        </SelectItem>
      )}
    </Select>
</div>


      <div className="mt-4 ml-1 text-default-500">Grand Quotation Cost : <Chip color="secondary" size="md" variant="flat">
      NZ$ {sum(quoteCost) ? sum(quoteCost) : 0} 
            </Chip> </div>
    </div>



    <div className="flex items-center p-4 justify-center gap-4 flex-row">
            <NextUIButton onClick={() => ApproveModal(3)} className="bg-[#00ff99] text-black">Accept</NextUIButton>
            <NextUIButton onClick={() => ApproveModal(2) } className="bg-red-500 text-white">Reject</NextUIButton>
            <NextUIButton className="bg-orange-500 text-black"  onClick={() => {navigate("/plotworks/quotation")}}>Back</NextUIButton>
    </div>

</Card>
    </div>
</div>
</>
  )
}

function ReportIcon({ height, width }) {
  return (
    <svg height={height} width={width} version="1.1" id="Layer_1" viewBox="0 0 512.001 512.001" fill="#000000">
      <circle fill="#71B84F" cx="256.001" cy="255.977" r="255.977"></circle>
      <path fill="#63A147" d="M129.244,418.535l90.958,90.958c11.733,1.655,23.667,2.508,35.851,2.508 c135.133,0,245.796-104.696,255.323-237.422L382.863,146.064L129.244,418.535z"></path>
      <path fill="#FFD630" d="M129.244,93.465h200.117l53.451,52.599v272.472H129.193V93.465H129.244z"></path>
      <path fill="#E7A20B" d="M355.736,119.79l27.127,26.224v41.618l-53.451-41.468l26.374-26.374H355.736z"></path>
      <path fill="#B2B3B3" d="M329.361,93.465l53.451,52.599l-53.451,0.1V93.465z"></path>
      <path fill="#F5BE18" d="M301.883,242.336c-11.282,15.092-29.333,24.87-49.641,24.87c-34.197,0-61.925-27.728-61.925-61.925 c0-32.141,24.519-58.616,55.908-61.625l6.067,61.625l49.641,37.055H301.883z M299.726,152.382 c16.346,11.132,27.127,29.934,27.127,51.246c0,13.338-4.212,25.673-11.382,35.801l-50.543-35.801L299.726,152.382z M258.259,133.177 c12.887,0,24.87,3.961,34.798,10.731l-34.798,51.194l-5.265-61.725c1.755-0.15,3.51-0.251,5.265-0.251V133.177z"></path>
      <path fill="#E84F4F" d="M299.325,237.522c-11.282,15.092-29.333,24.87-49.641,24.87c-34.197,0-61.925-27.728-61.925-61.925 c0-32.141,24.519-58.616,55.908-61.625l6.067,61.625l49.641,37.055L299.325,237.522L299.325,237.522z M255.702,128.364 c12.887,0,24.92,3.961,34.798,10.731l-34.798,51.246l-5.265-61.725C252.192,128.464,253.947,128.364,255.702,128.364z"></path>
      <path fill="#12B589" d="M297.17,147.618c16.346,11.132,27.127,29.934,27.127,51.194c0,13.338-4.212,25.673-11.382,35.801 l-50.543-35.801L297.17,147.618z"></path>
      <path fill="#FFFFFF" d="M180.84,355.507h30.286v34.598H180.84V355.507z M162.488,397.375h187.08v9.226h-187.08V397.375z M300.93,272.773h30.286v117.382H300.93V272.773z M260.917,346.631h30.286v43.523h-30.286V346.631z M220.904,325.271h30.286v64.934 h-30.286V325.271z"></path>
    </svg>
  )
}

export function secondsToDhms(seconds) {
    seconds = Number(seconds)
    var d = Math.floor(seconds / (3600 * 24))
    var h = Math.floor((seconds % (3600 * 24)) / 3600)
    var m = Math.floor((seconds % 3600) / 60)
    var s = Math.floor(seconds % 60)
    // console.log(d, h, m, s)
var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : ""
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : ""
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
    return dDisplay + hDisplay + mDisplay + sDisplay
  }

  export const sum = a => eval(a.join('+'));






