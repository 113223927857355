
import { Card, CardContent, CardFooter, CardTitle, CardHeader } from "../../shadcn/components/ui/card"
import { Separator } from "../../shadcn/components/ui/separator"
import { Button } from "../../shadcn/components/ui/button"
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChevronRightIcon, ChevronLeftIcon, DownloadIcon,FileTextIcon } from "@radix-ui/react-icons"
import { fetchGet, fetchPost, toSlug,fetchUpload } from "../../utils/helpers";
import { useEffect, useState ,useRef } from "react";
import { Urls } from "../../utils/AppConfig";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../actions";
import { quoteTypeMap,secondsToDhms,ColorMap,contractStatusMap,getFileExtension } from "../../lib/utils";
import { Chip } from "@nextui-org/react";
import { Button as NextUIButton,User } from "@nextui-org/react";

export default function Contract(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [quoteData, setQuoteData] = useState(null);
  const [consultantData, setConsultantData] = useState(null);
  const [signedContractFile, setSignedContractFile] = useState(null);
  const inputFile = useRef(null);

  let quoteId = searchParams.get("quoteId");

  const dispatch = useDispatch();

  let token = window.localStorage.getItem('token');
  useEffect(() => {
    loadData()
  }, []);


  const loadData = () => {
    fetchPost(Urls.GetQuoteDetailsById, { quoteId }, token)
      .then(r => {
        console.log(r);
        if (r.error) {
          return window.alert("Server error");
        }
        setQuoteData(r.data);
        getConsultantProfile(r.data.quote_created_by);
      })
      .catch(err => {
        console.log(err);
      })
  }


  async function getConsultantProfile(consultantId){
    try {
    fetchPost(Urls.GetConsultantProfile, { consultantId }, token)
         .then(r => {
           console.log(r);
           if (r.error) {   
             return window.alert("Server error");
           }
           console.log(r.data[0].comp_name)
           setConsultantData(r.data[0]);
         })
         .catch(err => {
           console.log(err);
         })
    } catch(error) {
        console.log(error)
    }
  }


  if (!quoteData) {
    return '...';
  }


  const contractHandler = async (e) =>{
if(e.target.files[0]){
  let ext = getFileExtension(e.target.files[0].name);
  if(ext == "pdf"){
    setSignedContractFile(e.target.files[0]);
  }else{
    alert("Upload in Pdf Format !");
    removeContractFile();
  }
}else{
  removeContractFile();
}
  }


  const removeContractFile = () => {
    inputFile.current.value = "";
    setSignedContractFile(null);
  };


const handleContractSubmit = async () => {

  if (!signedContractFile) {
    alert("Please select a file first.");
    return;
  }

  
  const formData = new FormData();
  formData.append('contractSigned', signedContractFile);
  formData.append("quoteId", quoteData['id']);
  formData.append("adminId", quoteData['quote_created_by']);
  formData.append("contractStatus", 2);
  formData.append("fullAddress", quoteData['full_address']);

  // for (var pair of formData.entries()) {
  //   console.log(pair[0] + " - " + pair[1]);
  // }

  try {
    dispatch(toggleLoader(true));
   await fetchUpload(Urls.UploadSignedContract,formData,token)
  .then(response => {
    dispatch(toggleLoader(false));
    if (response.error) {
      return window.alert("Failed to Upload the Document. Please, try again later.");
    }
    window.location.href = '/plotworks/contract';
  });
  } catch (error) {
    console.error("Error uploading file", error);
    alert("Error uploading file.");
  }

};




  return (
    <div className="flex items-center justify-center w-full py-3 px-2">
      <div className="md:p-5 p-1 w-full xl:w-2/3 mt-5">
      <div style={{float:'left', padding: 16}}>
              <Button className="font-bold" variant="" onClick={() => {
                navigate("/plotworks/contract")
              }}>
                Back
              </Button>
            </div>
        <Card className="w-full  p-0 text-white border-none bg-[#111111] border-2">
          <CardHeader>
            <div className="flex items-center justify-center gap-4">
              <div className="font-medium"><ReportIcon height={60} width={60} className=" text-[#00ff99]" /></div>
              <div><CardTitle className="text-[#00ff99]">Contract Details</CardTitle></div>
            </div>
          </CardHeader>

          <CardContent>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 items-center justify-center">

              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Quote Type</div>
                <div className="font-medium">{quoteTypeMap[quoteData['quote_type']]}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Quotation By</div>
                <div className="font-medium">{quoteData['quote_by']}</div>
              </div>

              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Parcel ID </div>
                <div className="font-medium">{quoteData['parcel_id']}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Address</div>
                <div className="font-medium">{quoteData['full_address']}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold "> From Date  </div>
                <div className="font-medium text-white">{quoteData['quote_from_date']}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">To Date</div>
                <div className="font-medium text-white">{quoteData['quote_to_date']}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold "> Lodgement TimeLine  </div>
                <div className="font-medium text-white">{secondsToDhms(quoteData['quote_timeline_duration'])}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Total Cost</div>
                <div className="font-medium text-white">{quoteData['quote_total_cost']}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Quote Created on </div>
                <div className="font-medium">{quoteData['quote_created_on']}</div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
                <div className="text-[#00ff99] font-extrabold ">Summary </div>
                <div className="font-medium">{quoteData['summary']}</div>
              </div>
            </div>
            <br/>

            <div className="grid grid-cols-1 gap-3 items-center justify-center">
           <div className="flex-col gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
           <div className="text-[#00ff99] font-extrabold text-center ">
            Consultant Details
            </div>
            <br/>
            <div className="font-medium text-white">
            <User
              avatarProps={
                {
                classNames:{
                  img:'opacity-1',
                  icon: "text-[#00ff99]/80",
                },
                size: "lg", 
                // icon:<AvatarIcon/>,
                src:consultantData?.profileUrl
              }}
  
              description={
                <p >
                {consultantData?.consultant_type}
              </p>
              
              }
              name={consultantData?.comp_name}
            />
                </div>
            </div>
           </div>
      
            <br/>
            <div className="grid grid-cols-1 gap-3 items-center justify-center">
           <div className="flex-col gap-4 p-4 border-2 border-none bg-[#333333] rounded-xl w-full items-center justify-center ">
           <div className="text-[#00ff99] font-extrabold text-center ">
            Quote Template
            </div>
            <br/>
            <div className="font-medium text-white">
                <pre>
                {quoteData['template_content']}
                </pre></div>
            </div>
           </div>
    
           


            <br/>
            <div className="grid grid-cols-1 gap-3 items-center justify-center">
                
            <div className="flex flex-col justify-center bg-[#333333] gap-4 p-4 border-2 border-none rounded-xl ">
            
            <div className="text-[#00ff99] font-extrabold text-center ">Contract Status <Chip color={ColorMap[quoteData['contract_status']]}>{contractStatusMap[quoteData['contract_status']]}</Chip></div>

            <div className="flex flex-row justify-center">
           <div className="flex justify-center">
              {quoteData['contractUrl'] ? (<>
              <div className="flex flex-col sm:flex-row items-center justify-center">
                <div className="p-2">
                <Button variant="pp" className="text-black font-bold" onClick={() => {
                  window.open(quoteData['contractUrl'], '_blank').focus();
                }}>
                  <DownloadIcon className="h-4 w-4" /><span className="px-3">Download Contract</span>
                </Button>
                </div>
              </div>
              </>) : ''}
            </div>
            <div className="flex justify-center">
              {quoteData['contractSignedUrl'] ? (<>
              <div className="flex flex-col sm:flex-row items-center justify-center">
                <div className="p-2">
                <Button variant="pp" className="text-black font-bold" onClick={() => {
                  window.open(quoteData['contractSignedUrl'], '_blank').focus();
                }}>
                  <DownloadIcon className="h-4 w-4" /><span className="px-3">Download Signed Contract</span>
                </Button>
                </div>
              </div>
              </>) : ''}
            </div>      
            </div>
           </div>

            {(quoteData['contract_status'] == 1)?
                <div>
            <div>Download and Upload the Signed Contract <span className="text-[#F31260] pl-[2px]">*</span></div>

            <form>  
              <div className="flex flex-row px-8 gap-4">
              <div className="basis-2/3 w-max">
              <br/>         
              < input className="mt-1 border rounded-2xl text-sm border-zinc-800 p-2 block w-full  dark dark:bg-zinc-800"
              required
              id="contract_signed"
                type = 'file' 
                 name = 'contract_signed' 
                accept =".pdf"
                onChange={contractHandler}
                ref={inputFile}
              />
              </div>
               <div className="basis-1/3 w-max">
               <br/>         
            {/* //     {galleryImgFile.preview ? 
            //     <>
            //     <label htmlFor="gallery_img_prv">Preview</label>
            //   <iframe 
            //   id="gallery_img_prv"
            //   height={500}
            //    src={galleryImgFile.preview}
            //   alt="gallery_img_prv"
            // />
            // </>
            // : null} */}
              <NextUIButton className="bg-red-400 mt-1 float-left text-black " onClick={removeContractFile}>
                  Remove
                </NextUIButton>
              </div> 
              </div>
              <br/>
                <NextUIButton className="bg-green-400 text-black" onClick={handleContractSubmit}>
                  Upload
                </NextUIButton>
              </form>


            </div>
            :''}

            {(quoteData['contract_status'] == 0)?
                <div>
            <div>No Contract Uploaded by the Consultant </div>
            </div>
            :''}

           </div>
           <div className="pt-2">
           <Separator className="my-2" />
           </div>

          </CardContent>

          <div className="flex items-center pb-4 justify-center gap-4 flex-row">
            
            <NextUIButton className="bg-orange-500 text-black"  onClick={() => {navigate("/plotworks/contract")}}>Back</NextUIButton>
    </div>
       
          {/* <CardFooter className="flex w-full gap-5 pt-1 px-4 md:px-6 justify-center">
            
          </CardFooter> */}
        </Card>
      </div>
    </div>
  )
}

function ReportIcon({ height, width }) {
  return (
    <svg height={height} width={width} version="1.1" id="Layer_1" viewBox="0 0 512.001 512.001" fill="#000000">
      <circle fill="#71B84F" cx="256.001" cy="255.977" r="255.977"></circle>
      <path fill="#63A147" d="M129.244,418.535l90.958,90.958c11.733,1.655,23.667,2.508,35.851,2.508 c135.133,0,245.796-104.696,255.323-237.422L382.863,146.064L129.244,418.535z"></path>
      <path fill="#FFD630" d="M129.244,93.465h200.117l53.451,52.599v272.472H129.193V93.465H129.244z"></path>
      <path fill="#E7A20B" d="M355.736,119.79l27.127,26.224v41.618l-53.451-41.468l26.374-26.374H355.736z"></path>
      <path fill="#B2B3B3" d="M329.361,93.465l53.451,52.599l-53.451,0.1V93.465z"></path>
      <path fill="#F5BE18" d="M301.883,242.336c-11.282,15.092-29.333,24.87-49.641,24.87c-34.197,0-61.925-27.728-61.925-61.925 c0-32.141,24.519-58.616,55.908-61.625l6.067,61.625l49.641,37.055H301.883z M299.726,152.382 c16.346,11.132,27.127,29.934,27.127,51.246c0,13.338-4.212,25.673-11.382,35.801l-50.543-35.801L299.726,152.382z M258.259,133.177 c12.887,0,24.87,3.961,34.798,10.731l-34.798,51.194l-5.265-61.725c1.755-0.15,3.51-0.251,5.265-0.251V133.177z"></path>
      <path fill="#E84F4F" d="M299.325,237.522c-11.282,15.092-29.333,24.87-49.641,24.87c-34.197,0-61.925-27.728-61.925-61.925 c0-32.141,24.519-58.616,55.908-61.625l6.067,61.625l49.641,37.055L299.325,237.522L299.325,237.522z M255.702,128.364 c12.887,0,24.92,3.961,34.798,10.731l-34.798,51.246l-5.265-61.725C252.192,128.464,253.947,128.364,255.702,128.364z"></path>
      <path fill="#12B589" d="M297.17,147.618c16.346,11.132,27.127,29.934,27.127,51.194c0,13.338-4.212,25.673-11.382,35.801 l-50.543-35.801L297.17,147.618z"></path>
      <path fill="#FFFFFF" d="M180.84,355.507h30.286v34.598H180.84V355.507z M162.488,397.375h187.08v9.226h-187.08V397.375z M300.93,272.773h30.286v117.382H300.93V272.773z M260.917,346.631h30.286v43.523h-30.286V346.631z M220.904,325.271h30.286v64.934 h-30.286V325.271z"></path>
    </svg>
  )
}




