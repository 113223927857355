"use client"

import { ColumnDef } from "@tanstack/react-table"

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
import { ChevronRightIcon,ChevronLeftIcon,DownloadIcon } from "@radix-ui/react-icons"
import { TiTick } from "react-icons/ti";

import { Button } from "../../../../shadcn/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../shadcn/components/ui/dropdown-menu"


import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../shadcn/components/ui/tooltip"

import { ColorMap,contractStatusMap,quoteTypeColorMap,quoteTypeMap } from "../../../../lib/utils";
import { Chip } from "@nextui-org/react";

function Capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}

export const columns= [
{
    header: "S No",
        cell: ({ row }) => {
      return (
        <div>{row.index + 1}</div>
      )
    },
},

{
  header: "Quote Type",
  cell: ({ row }) => {
    return (
      <div>
      <Chip className={(quoteTypeColorMap[row.original['quote_type']])}>{quoteTypeMap[row.original['quote_type']]}</Chip>
      </div>
    )
  },
},

{
  accessorKey: "comp_name",
  header: "Company Name",
},

  {
    accessorKey: "parcel_id",
    header: "Parcel ID",
  },
  {
    accessorKey: "full_address",
    header: "Address",
  },
  {
    accessorKey: "quote_created_on",
    header: "Quote Created On",
  },
  {
    accessorKey: "contract_status",
    header: "Contract Status",
        cell: ({ row }) => (
      <div><Chip color={ColorMap[row.original['contract_status']]}>{contractStatusMap[row.original['contract_status']]}</Chip></div>
    ),
  },
]






