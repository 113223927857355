

import React, { useState,useEffect } from 'react';
import {Accordion, AccordionItem} from "@nextui-org/react";

import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Navigation, Pagination, Scrollbar,Autoplay,EffectCoverflow,EffectFlip,EffectCreative,Zoom } from 'swiper/modules';

import costing1 from "../../images/reports/costing.jpg";
import costing2 from "../../images/reports/costing2.jpg";
import costing3 from "../../images/reports/costing3.jpg";

import map1 from "../../images/reports/map1.jpg";
import map2 from "../../images/reports/map2.jpg";
import map3 from "../../images/reports/map3.jpg";

import plan1 from "../../images/reports/plan.jpg";
import plan2 from "../../images/reports/plan2.jpg";
import plan3 from "../../images/reports/plan3.jpg";


import Stormwater1 from "../../images/reports/Stormwater.jpg";
import Stormwater2 from "../../images/reports/Stormwater2.jpg";

import Wastewater1 from "../../images/reports/Wastewater.jpg";
import Wastewater2 from "../../images/reports/Wastewater2.jpg";

import dwelling1 from "../../images/reports/Graphic representations of dwellings.jpg";
import driveway1 from "../../images/reports/Driveway, outlook space and outdoor living space.jpg";
import flags1 from "../../images/reports/Red and green flags.jpg";
import water1 from "../../images/reports/Stormwater and wastewater engineering.jpg";
import zone1 from "../../images/reports/Zone perimeters.jpg";






import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-creative';
import 'swiper/css/zoom';
import { Link } from 'react-router-dom';

const SwiperImages = ({swipeData}) => {        
  return (
    <Swiper
  modules={[Navigation, Pagination, Scrollbar,Autoplay,EffectCreative,EffectCoverflow,EffectFlip,Zoom]}
  centeredSlides={true}
  grabCursor={true}

  // effect={'flip'}

  effect={'coverflow'}
  slidesPerView={'auto'}
  coverflowEffect={{
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  }}
  // effect={'creative'}
  // creativeEffect={{
  //   prev: {
  //     shadow: true,
  //     translate: [0, 0, -400],
  //   },
  //   next: {
  //     translate: ['100%', 0, 0],
  //   },
  // }}
  spaceBetween={30}
  loop={true}
  onSwiper={(swiper) => console.log(swiper)}
  onSlideChange={() => console.log('slide change')}
  autoplay={{
    delay: 1500,
    disableOnInteraction: false,
  }}
>
{swipeData.map((item,idx)=>(
          <SwiperSlide><img src={item} className='w-full h-[400px] object-fill border-2 border-[#00ff99] rounded-lg'/></SwiperSlide>
            ))}
</Swiper>
        )}


const ReportSection = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const services =
    [
        {
            id:"1",
            title:"Graphical representations of dwellings",
            content:"Our system harnesses planning, architectural and engineering information to produce detailed graphical representations of potential development scenarios. These include single-storey dwellings, townhouses, duplexes, and zero-lot boundary designs. Each representation highlights architectural designs, floor plans, elevations, and key property features to offer a visual understanding of layout and aesthetics.",
            side:'left',
            images:[map1,map2,map3]
          },
        {
            id:"2",
            title:"Driveway, outlook space and outdoor living space",
            content:"Our reports highlight the potential design and layout of driveways, outlook spaces, and outdoor living areas.They illustrate how these spaces integrate into the overall design of the property, including their size, location, access points, landscaping features, and any amenities such as patios, decks, or gardens.",
            side:'right',
            images:[plan1,plan2,plan3]
          },
        {
            id:"3",
            title:"Red and green flags",
            content:"Plot Potential's system scans properties to identify red and green flags for potential developments.Red flags include site constraints, feasibility restrictions, and Council compliance issues. Identifying these potential issues from the start allows for early resolution and compliance with laws and guidelines.Green flags include affordability, ease of construction, and utility serviceability.",
            side:'left',
            images:[dwelling1,driveway1,flags1,zone1,water1,costing1]
          },
        {
            id:"4",
            title:"Zone perimeters",
            content:"Each report identifies boundaries and perimeters that may affect potential developments.Reports include setbacks, easements, utility corridors, and other physical considerations. They also include legal limitations such as local council regulations that affect the design and construction process.",
            side:'right',
            images:[dwelling1,driveway1,flags1,zone1,water1,costing1]
          },
        {
            id:"5",
            title:"Stormwater and wastewater engineering",
            content:"Our system assesses the connectivity options for storm- and wastewater to your proposed development.Reports include detailed plans of pipeline connectivity locations and ability to connect these to your property. They even include pipe lengths, invert levels and manhole connections.",
            side:'left',
            images:[Stormwater1,Stormwater2,Wastewater1,Wastewater2]
          },
        {
            id:"6",
            title:"Project Costing",
            content:"Plot Potential reports estimate your project's total cost and expected ROI. Reports break the cost down into the design stage, consent stage and build stage, to help you assess the project's financial feasibility and cashflow arrangements.",
            side:'right',
            images:[costing1,costing2,costing3]
          }
    ];

    return <>
      <section  className='scroll-mt-[72px]' style={{ fontFamily: 'Verdana, sans-serif' }}>
      <div style={{ textAlign: 'center', padding: 0, background: '#111111', paddingTop: 65, paddingBottom: 65 }}>
                <div elevation={0} style={{ textAnchor: 'middle', background: 'transparent', minHeight: '100vh' }}>
                <p className="head_font2 md:text-5xl text-4xl font-bold text-[#00ff99] pt-5 ">
                PLOT POTENTIAL REPORTS
                    </p>
                    <div className='content-text justify-center items-center text-center py-4'>
                    Our reports consolidate data, regulations, site-specific requirements and restraints to showcase the full potential of your property. 
                                </div>



            <div className=' flex items-center justify-center'>
            <div className='md:w-3/4 w-11/12'>


                    <Accordion variant='splitted'
            itemClasses={
                {
                    base:"!bg-[#333333] text-white hover:bg-gradient-to-r from-[#07b16d88] to-[#71aec088]  hover:text-white",
                    title:"text-white"
                }
            }
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            height: "auto",
            transition: {
              height: {
                type: "spring",
                stiffness: 500,
                damping: 30,
                duration: 1,
              },
              opacity: {
                easings: "ease",
                duration: 1,
              },
            },
          },
          exit: {
            y: -10,
            opacity: 0,
            height: 0,
            transition: {
              height: {
                easings: "ease",
                duration: 0.25,
              },
              opacity: {
                easings: "ease",
                duration: 0.3,
              },
            },
          },
        },
      }}
    >
            {services.map((item,idx)=>(
                <AccordionItem key={item.id} aria-label={item.id} title={item.title} className='text-left text-xl' >

                {item.side == 'right'? 
           <div className='lg:space-x-4 flex space-y-4 lg:flex-row flex-col'>
          <div className='lg:w-5/12 w-full'>
          <SwiperImages swipeData={item.images} />
          </div>

               <div className='lg:w-7/12 w-full'>
               {item.content}
               </div>
               </div>
          : 
              <div className='lg:space-x-4 space-y-4 flex lg:flex-row flex-col'>
                <div className='lg:w-7/12 w-full'>
                    {item.content}
                    </div>
                  <div className='lg:w-5/12 w-full'>
                  <SwiperImages swipeData={item.images} />
                  </div>
              </div>
        }

                </AccordionItem>
            ))}
            </Accordion>
            </div>
            </div>
                    </div>
                    </div>
                    </section>
</>
}


export default ReportSection;